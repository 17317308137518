.App {
  display: flex;
  background-color: #282c34;
  color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.sidemenu {
  width: 240px;
  background-color: #202123;
  padding: 10px;
}

.side-menu-button {
  padding: 12px;
  border: 1px solid white;
  border-radius: 5px;
  text-align: left;
  transition: ease 0.25s all;
}

.side-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.side-menu-button span {
  padding-left: 6px;
  padding-right: 12px;
}

.chatbox {
  flex: 1;
  background-color: #343541;
  position: relative;
}

.chat-log {
  text-align: left;
  height: calc(100% - 90px);
  overflow-y: scroll;
}

.chat-message.chatgpt {
  background-color: rgba(255, 255, 255, 0.1);
}

.chat-message-center {
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 640px;
  margin-left: auto; 
  margin-right: auto;
  display: flex;
}

.Avatar-me {
  background: #5436DA;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Avatar-ai {
  background: #00A699;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  padding-left: 20px;
  flex: 1;
}

.chat-input-holder {
  padding: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.chat-input-textarea {
  background-color: #40414f;
  width: calc(100% - 70px);
  padding: 12px;
  color: white;
  font-size: 1.25em;
  border-radius: 5px;
  border: none;
  outline: none;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  padding-right: 40px;
}

.chat-input-submit-button {
  position: absolute;
  right: 32px;
  bottom: 32px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-input-submit-button svg {
  width: 20px;
  height: 20px;
  color: #fff;
}

.chat-input-submit-button:hover svg {
  color: #10a37f;
}
